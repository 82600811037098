import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { CategoryPreview, FeaturedProducts, ProductList, StoreLayout, StorePromo } from "@lgastler-ba/ui";
import type { ProductListQuery } from "../../graphql-types";
import {
  gatsbyGraphMapProducts,
  mapCommerceProduct,
} from "@lgastler-ba/shopify";

function IndexPage({ data }: PageProps<ProductListQuery>) {
  const { products, featuredProducts } = data;

  const graphMappedProducts = gatsbyGraphMapProducts(products.edges);
  const graphMappedFeaturedProducts = gatsbyGraphMapProducts(
    featuredProducts.edges
  );

  const uiProducts = mapCommerceProduct(graphMappedProducts);
  const uiFeaturedProducts = mapCommerceProduct(graphMappedFeaturedProducts);
  const uiCategories = data.categories.edges.map(({ node }) => ({
    name: node.title ?? "No title",
    href: `/search?category=${node.handle}`,
    imageSrc: node.image?.gatsbyImageData.images.fallback.src,
  }));

  return (
    <StoreLayout>
      <StorePromo />
      <CategoryPreview categories={uiCategories} />
      <FeaturedProducts products={uiFeaturedProducts} />
      <ProductList products={uiProducts} />
    </StoreLayout>
  );
}

export const query = graphql`
  fragment productConnection on ShopifyProductConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        title
        vendor
        handle
        priceRange: priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          gatsbyImageData(height: 900)
          altText
          width
          height
        }
        variants {
          id
        }
      }
    }
  }

  query ProductList {
    products: allShopifyProduct {
      ...productConnection
    }
    featuredProducts: allShopifyProduct(limit: 3) {
      ...productConnection
    }
    categories: allShopifyCollection(limit:4) {
    edges {
      node {
        title
        handle
        image {
          id
          width
          height
          gatsbyImageData(height: 900)
        }
      }
    }
  }
  }
`;

export default IndexPage;
